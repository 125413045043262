import React, { Suspense } from "react";
import { graphql } from "gatsby";
import {
  colors,
  fontStyles,
  zIndex,
  transitions,
  navHeight,
  cursors,
} from "../styles/variables";
import styled from "styled-components";
import Layout from "../components/Layout";
import ProjectNav from "../components/ProjectNav";
import { useAnimationStore } from "../stores/AnimationStore";
import { BackgroundImagery } from "../components/BackgroundImagery";
// import Canvas from "../components/PaperCanvas";
import loadable from "@loadable/component";
import { useGesture, useWheel, useDrag } from "@use-gesture/react";
import { useSpring, animated } from "react-spring";
import Navbar from "../components/Navbar";
import { ProjectOverviewImage } from "../components/ProjectOverviewImage";
import { mediaQueries } from "../styles/queries";
import Button from "../components/Button";

const ReactPaperComponent = loadable(
  async () => await import("../components/PaperCanvas"),
  {
    fallback: <></>,
  }
);
const H1 = styled.h1`
  color: ${(props) => (props.color ? props.color : colors.black)};
  -webkit-text-stroke: ${(props) =>
    (props.fontColor !== colors.black ? "0.9px" : "1px") +
    (props.fontColor ? props.fontColor : "transparent")};
  ${fontStyles.h1};
  /* text-transform: uppercase; */
  hyphens: auto;
  white-space: pre-line;
  z-index: ${zIndex.low};
  margin-block: 0px;
  transition: ${transitions.t1};
`;

const CanvasWrapper = styled(animated.div)`
  position: fixed;
  position: absolute;
  left: 0;
  min-width: 100%;
  width: 100%;
  height: calc(var(--100dvh));
  z-index: ${zIndex.lowest - 1};
  top: 0;
`;

const PreviewWrapper = styled(animated.div)`
  z-index: ${zIndex.high};
  ${(props) => !props.visible && `opacity: 0;`}
`;

// const NavBar = styled.div`
//   ${(props) => !props.visible && `opacity: 0;`}
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   grid-column-gap: 2rem;
//   grid-template-rows: 1fr;
//   z-index: ${zIndex.medium};
//   margin-bottom: 3rem;
//   p:last-child {
//     text-align: right;
//   }
// `;

const TextInfo = styled.div`
  ${(props) => !props.isVisible && `opacity: 0;`}
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  ${mediaQueries.medium} {
    grid-template-columns: 1fr;
  }
  grid-column-gap: 2rem;
  grid-template-rows: 1fr;
  z-index: ${zIndex.medium};
  margin-top: 1rem;
`;

const NarrowText = styled.p`
  ${(props) => !props.isVisible && `display: none;`}

  ${(props) =>
    props.hideOnMobile && `${mediaQueries.medium} { display: none; }`}
  border-top: 2px solid ${colors.black};
  margin-block-end: 0;
  padding-top: 1rem;
  ${fontStyles.body};
  width: 85%;
`;

const InfoTextBig = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  ${fontStyles.h3};
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* min-height: var(--100dvh); */
  grid-area: 2 / 2 / 7 / 7;
  ${mediaQueries.medium} {
    grid-area: 2/2/5/5;
  }
  display: flex;
  flex-direction: column;
  /* padding-top: 1rem;
  padding-bottom: calc(95px + 2rem);
  padding-left: 1rem;
  padding-right: 1rem; */
  margin: 0 auto;
  cursor: ${cursors.cross};
`;

const ArrowWrapper = styled.div`
  grid-area: 7 / 2 / 8 / 2;
  ${mediaQueries.medium} {
    grid-area: 7/2/8/2;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: ${(props) => (props.visible === true ? 1 : 0)};
  transition: ${transitions.t1};
  margin-left: -10px;
`;
const TurnedText = styled.div`
  transform: rotateZ(90deg);
  ${fontStyles.body};
`;

const DontBreak = styled.span`
  word-break: normal;
  display: inline-block;
`;
const ScrollArrow = (props) => {
  return (
    <ArrowWrapper {...props}>
      <TurnedText>scroll</TurnedText> ↓
    </ArrowWrapper>
  );
};

const IndexPage = ({ data }) => {
  const {
    ballsVisible,
    activeProject,
    setScrolled,
    scrolled,
    setAllProjects,
    allProjects,
    backgroundColor,
    resetColors,
  } = useAnimationStore();

  const forwarRef = React.useRef();

  if (activeProject === null && backgroundColor !== colors.white) {
    resetColors();
    console.log("setting background color to white");
  }

  if (allProjects?.length === 0 || allProjects === null) {
    setAllProjects(data?.page?.edges);
  }

  const canvasSpring = useSpring({
    transform: scrolled < 0 ? `translateY(0vh)` : `translateY(-100vh)`,
    config: { mass: 1, tension: 170, friction: 50 },
  });

  const previewSpring = useSpring({
    transform: scrolled > 0 ? `translateY(0vh)` : `translateY(100vh)`,
    config: { mass: 1, tension: 170, friction: 50 },
  });

  const bindWheel = useWheel(({ event, delta: [x, y] }) => {
    if (y > 5 && scrolled < 1) {
      setScrolled(y);
    } else if (y < -5 && scrolled > 0) {
      setScrolled(y);
    }
  });

  const bindDrag = useDrag(({ event, down, movement: [mx, my] }) => {
    // down
    if (my < 0 && scrolled < 1) {
      setScrolled(20);
    } else if (my > 0 && scrolled > 0) {
      // up
      setScrolled(-20);
    }
  });

  return (
    <Layout noOverflow noFooter noTouch {...bindWheel()} {...bindDrag()}>
      <ContentWrapper>
        <Navbar visible={ballsVisible} />
        {/* <NavBar visible={ballsVisible}>
          <InfoTextBig>Theodor Hillmann</InfoTextBig>
          <InfoTextBig>Product Design</InfoTextBig>

          <InfoTextBig>Berlin Based</InfoTextBig>
          <InfoTextBig> </InfoTextBig>
          <InfoTextBig>Menu</InfoTextBig>
        </NavBar> */}

        {activeProject?.frontmatter?.title ? (
          <H1
            color={activeProject?.frontmatter?.color}
            fontColor={activeProject?.frontmatter?.fontColor}
          >
            &nbsp;&nbsp;{activeProject?.frontmatter?.title}
          </H1>
        ) : scrolled < 1 ? (
          <H1 hide={activeProject !== null}>
            Most problems can be solved by empathy,&nbsp;
            <DontBreak> research</DontBreak> & bold decisions.
            {/* The digital realm is the sarco&shy;phagus we all wanted. */}
          </H1>
        ) : (
          <H1 hide={activeProject !== null}>Selected Work</H1>
        )}
        <TextInfo isVisible={true}>
          <NarrowText isVisible={scrolled > 0 && ballsVisible}>
            As a Berlin-based product designer I bring a holistic approach to
            driving growth for innovative product companies through my design,
            research and prototyping skills.
          </NarrowText>
          <Button
            marginTop={"3rem"}
            mobileMarginTop={"1rem"}
            isHidden={scrolled > 0}
            to={`contact`}
          >
            {" "}
            Let's work together!
          </Button>
          {/* <NarrowText hideOnMobile>
            There is an off feeling in the air. Not everything we see makes
            sense on every occasion. It sometimes suffers from within. The
            metaverse solves nothing.
          </NarrowText> */}
        </TextInfo>
      </ContentWrapper>
      <BackgroundImagery></BackgroundImagery>
      {/* <PreviewWrapper visible={ballsVisible} style={previewSpring}>
        <ProjectOverviewImage></ProjectOverviewImage>
      </PreviewWrapper> */}
      <ScrollArrow visible={ballsVisible && scrolled < 0}></ScrollArrow>
      <CanvasWrapper style={canvasSpring} ref={forwarRef}>
        {ballsVisible && (
          <ReactPaperComponent forwarRef={forwarRef}></ReactPaperComponent>
        )}
        {/* <Suspense fallback={null}>{ballsVisible && <BallScene />}</Suspense> */}
      </CanvasWrapper>
      <ProjectNav />
    </Layout>
  );
};

export default IndexPage;

export const HomeQuery = graphql`
  query AllProjectsQuery {
    page: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "templates/project" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            slug
            templateKey
            color
            fontColor
            layout
            featuredImages {
              alt
              square
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 1000
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              videoSrc {
                childVideoFfmpeg {
                  webm: transcode(
                    outputOptions: ["-crf 20", "-b:v 0"]
                    maxWidth: 1280
                    maxHeight: 720
                    fileExtension: "webm"
                    codec: "libvpx-vp9"
                  ) {
                    width
                    src
                    presentationMaxWidth
                    presentationMaxHeight
                    originalName
                    height
                    fileExtension
                    aspectRatio
                  }
                  mp4: transcode(
                    maxWidth: 1280
                    maxHeight: 720
                    fileExtension: "mp4"
                    codec: "libx264"
                  ) {
                    width
                    src
                    presentationMaxWidth
                    presentationMaxHeight
                    originalName
                    height
                    fileExtension
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
