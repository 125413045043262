import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  transitions,
  zIndex,
  navHeight,
  borderRadius,
  cursors,
} from "../styles/variables";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useAnimationStore } from "../stores/AnimationStore";
import { widths } from "../styles/variables";
import { mediaQueries } from "../styles/queries";
import { Video } from "gatsby-video";
import { useWheel, useMove, useHover } from "@use-gesture/react";
import { useSpring, animated, to, config } from "react-spring";
import { useMeasure } from "react-use";
import { throttle } from "lodash";
import { navigate } from "gatsby";
import { GridImage } from "./GridImage";

const Wrapper = styled(animated.div)`
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: calc(100vw + 0rem);
  height: calc(var(--100dvh) + 0rem);
  /* max-width: ${widths.max}; */
  margin: auto;
  z-index: ${zIndex.high};
  display: grid;
  grid-template-columns: 0rem repeat(5, 1fr) 0rem;
  grid-template-rows: 0rem repeat(5, 1fr) ${navHeight};
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  cursor: ${cursors.cross};
`;

const StyledImage = styled(GatsbyImage)`
  z-index: ${zIndex.highest};
  cursor: ${cursors.arrow}!important;
  position: relative;
  /* height: 100%; */
  overflow: hidden;
  background: #000;
  aspect-ratio: 1 / 1;
  min-width: unset;
  img {
    object-fit: cover;
    object-position: 50% 50%;
    aspect-ratio: 1 / 1;

    overflow: hidden;
    background: #000;
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "100%"};
    /* transform: scale(2); */
    z-index: -1;
  }
`;
const StyledVideo = styled(Video)`
  z-index: ${zIndex.low};
  position: relative;
  /* height: 100%; */
  overflow: hidden;
  background: #000;
  aspect-ratio: 1/1;
  div {
    height: 100%;
    background: #000;
  }
  video {
    object-fit: cover !important;
    object-position: 50% 50%;
    aspect-ratio: ${(props) => (props.square ? "1 / 1" : "unset")};

    overflow: hidden;
    background: #000;
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "100%"};
    /* transform: scale(2); */
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const FirstImageWrapper = styled(animated.div)`
  cursor: inherit;
  background: transparent;
  display: flex;
  align-self: flex-end;
  grid-area: 4/5/7/7;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: ${transitions.t1};

  img,
  div {
    cursor: ${cursors.arrow}!important;
    height: 100% !important;
    border-radius: 100%;
    aspect-ratio: 1/1;
    /* transition: ${transitions.t1}; */
  }
  video {
    object-fit: cover !important;
    object-position: 50% 50%;
  }
`;

export const ProjectOverviewImage = () => {
  const { allProjects } = useAnimationStore();

  const [ticker, setTicker] = useState(0);

  const wrapperPosition = useRef(null);
  const wrapperRef = useRef(null);

  const trans = ([x, y], scale) =>
    `translate3d(${x}px,${y}px,0) scale(${scale})`;

  const [springProps, api] = useSpring(() => ({
    transform: `translate3d(${0}px,${0}px,0) scale(${1})`,
    // config: config.,
  }));

  const mapValue = (value, in_min, in_max, out_min, out_max) => {
    return (
      ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
  };

  const breathIn = useRef(false);
  const bind = useMove(
    ({
      velocity: [vx, vy],
      direction: [dx, dy],
      xy: [x, y],
      initial: [initialX, initialY],
    }) => {
      const {
        x: rectX,
        y: rectY,
        width,
        height,
      } = wrapperRef?.current?.getBoundingClientRect();
      const animationRage = 10;
      // console.log(x - ox, y - oy);
      const checkIfHoveringRect = (x, y, rectX, rectY, width, height) => {
        if (x > rectX && x < rectX + width && y > rectY && y < rectY + height) {
          return true;
        } else {
          return false;
        }
      };
      if (!checkIfHoveringRect(x, y, rectX, rectY, width, height)) {
        api({
          transform: `translate3d(${mapValue(
            x - rectX,
            0,
            window.innerWidth,
            -animationRage,
            animationRage
          )}px,${mapValue(
            y - rectY,
            0,
            window.innerHeight,
            -animationRage,
            animationRage
          )}px,0) scale(${1})`,
        });
      } else
        api({
          transform: `translate3d(${0}px,${0}px,0) scale(${1.0})`,
        });
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (ticker === allProjects.length - 1) {
        setTicker(0);
      } else {
        setTicker(ticker + 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [ticker, setTicker, breathIn]);

  const imageArray = allProjects?.map(({ node }) => {
    return getImage(node?.frontmatter.featuredImages[0].src);
  });
  // allProjects && getImage(activeProject?.frontmatter.featuredImages[0]?.src);

  const handleClick = () => {
    navigate(allProjects[ticker - 1]?.node?.frontmatter?.slug);
  };
  return (
    <Wrapper {...bind()}>
      <FirstImageWrapper
        style={{ ...springProps }}
        ref={wrapperRef}
        onClick={() => handleClick()}
      >
        <StyledImage
          layout="fullWidth"
          borderRadius="17vw 0% 0% 17vw"
          image={imageArray[ticker]}
          alt="project medley"
        />
      </FirstImageWrapper>
      <GridImage
        image={imageArray[ticker]}
        gridArea={"2/6/3/7"}
        borderRadius={"100%"}
        alt={"portfolio image"}
        square={true}
      ></GridImage>
    </Wrapper>
  );
};
