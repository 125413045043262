import React from "react";
import styled from "styled-components";
import {
  transitions,
  zIndex,
  navHeight,
  borderRadius,
  cursors,
  grid,
} from "../styles/variables";
import { useAnimationStore } from "../stores/AnimationStore";
import { widths } from "../styles/variables";
import { mediaQueries } from "../styles/queries";
import { GridImage } from "./GridImage";

const Wrapper = styled.div`
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: calc(100vw + 0rem);
  height: calc(var(--100dvh) + 0rem);
  margin: auto;
  z-index: ${zIndex.lowest};
  ${grid.inner};
  /* padding-top: ${navHeight} - 20px; */
  padding-bottom: ${navHeight};
  cursor: ${cursors.cross};
  ${mediaQueries.medium} {
    display: none;
  }
`;

export const BackgroundImagery = () => {
  const { activeProject } = useAnimationStore();
  const altLayout = activeProject?.frontmatter?.layout || 1;

  const video1 =
    activeProject?.frontmatter.featuredImages[0]?.videoSrc?.childVideoFfmpeg;

  const video2 =
    activeProject?.frontmatter.featuredImages[1]?.videoSrc?.childVideoFfmpeg;
  const video3 =
    activeProject?.frontmatter.featuredImages[2]?.videoSrc?.childVideoFfmpeg;

  const imagesConfig = [
    // FIRST IMAGE
    [
      {
        gridArea: "2/4/8/7",
        borderRadius: "1rem",
      },
      {
        gridArea: "2/5/5/7",
        borderRadius: "1rem",
      },
      {
        gridArea: "4/4/8/7",
        borderRadius: "1rem",
      },
      {
        gridArea: "2/4/5/6",
        borderRadius: "1rem",
      },
      {
        gridArea: "3/3/8/7",
        borderRadius: "1rem",
      },
    ],
    // SECOND IMAGE
    [
      {
        gridArea: "3/2/8/4",
        borderRadius: "1rem",
      },
      {
        gridArea: "2/2/8/5",
        borderRadius: "1rem",
      },
      {
        gridArea: "4/2/8/4",
        borderRadius: "1rem",
      },
      {
        gridArea: "3/2/8/4",
        borderRadius: "1rem",
      },
      {
        gridArea: "0",
        borderRadius: "1rem",
      },
    ],
    // THIRD IMAGE
    [
      {
        gridArea: "3/2/5/4",
        borderRadius: "1rem",
      },
      {
        gridArea: "5/5/8/7",
        borderRadius: "1rem",
      },
      {
        gridArea: "2/2/4/5",
        borderRadius: "1rem",
      },
      {
        gridArea: "5/4/8/7",
        borderRadius: "1rem",
      },
      {
        gridArea: "0",
        borderRadius: "1rem",
      },
    ],
  ];

  return (
    <Wrapper>
      <GridImage
        image={activeProject?.frontmatter.featuredImages[0]?.src}
        video={video1?.mp4 && [video1?.webm, video1?.mp4]}
        gridArea={imagesConfig[0][altLayout - 1].gridArea}
        square={activeProject?.frontmatter.featuredImages[0]?.square}
        borderRadius={imagesConfig[0][altLayout - 1].borderRadius}
        alt={activeProject?.frontmatter.featuredImages[0]?.alt}
        scaleDown={activeProject?.frontmatter.featuredImages[0]?.scaleDown}
      ></GridImage>

      <GridImage
        image={activeProject?.frontmatter.featuredImages[1]?.src}
        video={video2?.mp4 && [video2?.webm, video2?.mp4]}
        gridArea={imagesConfig[1][altLayout - 1].gridArea}
        square={activeProject?.frontmatter.featuredImages[1]?.square}
        borderRadius={imagesConfig[1][altLayout - 1].borderRadius}
        alt={activeProject?.frontmatter.featuredImages[1]?.alt}
        scaleDown={activeProject?.frontmatter.featuredImages[1]?.scaleDown}
      ></GridImage>

      <GridImage
        image={activeProject?.frontmatter.featuredImages[2]?.src}
        video={video3?.mp4 && [video3?.webm, video3?.mp4]}
        gridArea={imagesConfig[2][altLayout - 1].gridArea}
        square={activeProject?.frontmatter.featuredImages[2]?.square}
        borderRadius={imagesConfig[2][altLayout - 1].borderRadius}
        alt={activeProject?.frontmatter.featuredImages[2]?.alt}
        scaleDown={activeProject?.frontmatter.featuredImages[2]?.scaleDown}
      ></GridImage>
    </Wrapper>
  );
};
